var $searchIcon = $('.header__search__icon');
var $searchInput = $('.header__right .header__search');
var $mobileSearchContainer = $('.search__mobile');
var $mobileUserPopover = $('.user .popover');
var $mobileSearchInput = $('.mobile-search.header__search');
var $mobileHeaderBanner = $('.header-banner');
var $headerMenu = $('.header__menu');
var $menuList = $('.menu__list');
var $menuLink = $('.menu__link');
var $dropdownLink = $('.dropdown-link');
var $dropdownItem = $('.dropdown-item');
var $postalCodeModal = $('.container-modal-postal.js-modal-postal .simple-modal');

function adjustContainerClasses() {
    $(window).width() < 1342 ? $headerMenu.removeClass('col-6') : $headerMenu.addClass('col-6');
}

function toggleSearch() {
    if ($(window).width() > 1340) {
        if ($searchInput.hasClass('d-none')) {
            $searchInput.removeClass('d-none');
        } else if ($searchInput.val() === '') {
            $searchInput.addClass('d-none');
            $searchIcon.removeClass('d-none');
        }
    } else if ($(window).width() < 1340) {
        if ($mobileSearchInput.hasClass('d-none')) {
            $mobileSearchInput.removeClass('d-none');

            if ($mobileHeaderBanner !== undefined && $mobileHeaderBanner !== null) {
                var height = `${$mobileHeaderBanner.height() + 68}px`;
                $mobileSearchContainer.css('top', height);
            }
        } else {
            $mobileSearchInput.addClass('d-none');
        }
    }
    adjustContainerClasses();
}

$('.close-menu .close-button').on('click', function (event) {
    event.preventDefault();

    $menuList.removeAttr('style');
    $menuLink.removeAttr('style');
    $dropdownItem.removeAttr('style');
    $dropdownLink.removeAttr('style');
});

$(document).on('click', function (event) {
    if ($(event.target).closest($searchIcon).length) {
        toggleSearch();
    } else if (!$(event.target).closest('.header__right .header__search').length) {
        if (!$searchInput.hasClass('d-none') && $searchInput.val() === '') {
            $searchInput.addClass('d-none');
            $searchIcon.removeClass('d-none');
            adjustContainerClasses();
        }
    }
});

$(window).on('resize', function () {
    adjustContainerClasses();
}).trigger('resize');

// fix header on top on scrolldown
$(document).ready(function () {
    $(window).scroll(function () {
        var scrollPos = $(window).scrollTop();
        $('header').each(function () {
            var currHeader = $(this);

            if (scrollPos) {
                currHeader.addClass('fixed');
            } else {
                currHeader.removeClass('fixed');
            }
        });
    });
});

// hide/show user profile panel
$(document).ready(function () {
    if ($(window).width() < 992) {
        $('.login-userPanel_Btn').click(function () {
            var $popover = $(this).find('.popover.popover-bottom');
            var height = `${$mobileHeaderBanner.height() + 68}px`;
            $mobileUserPopover.css('top', height);
            $popover.toggleClass('d-none');
        });
    } else {
        $('.login-userPanel_Btn').hover(function () {
            var $popover = $(this).find('.popover.popover-bottom');
            $popover.toggleClass('d-none');
        });
    }
});
// show/hide search icon inside the search input if its not empty
$(document).ready(function () {
    if ($(window).width() > 1340) {
        var searchIcon = $('.search-icon-input');
        $('.header__search .form-control.search-field').on('keyup', function () {
            if ($(this).val().length > 3) {
                $(searchIcon).addClass('d-none');
                $('.fa-times.reset-button').addClass('d-sm-block');
            } else if ($(this).val().length < 3) {
                $(searchIcon).removeClass('d-none');
                $('.fa-times.reset-button').removeClass('d-sm-block');
            }
        });
        $('.fa-times.reset-button').on('click', function () {
            searchIcon.removeClass('d-none');
        });
    }
});

$(document).ready(function () {
    $('.postal-code-text').on('click', function () {
        const modal = $('.js-modal-postal');
        var totalPaddingTop = 115;

        if ($mobileHeaderBanner !== undefined && $mobileHeaderBanner !== null && $mobileHeaderBanner.length > 0 && ($(window).width() > 1340)) {
            totalPaddingTop = `${totalPaddingTop + 40}px`;
        }

        $postalCodeModal.css('padding-top', totalPaddingTop);

        modal.removeClass('d-none');
    });

    $('.close-img').on('click', function () {
        $('.js-modal-postal').addClass('d-none');
    });

    window.onclick = function (event) {
        if ($(event.target).attr('class') === 'simple-modal') {
            $('.js-modal-postal').addClass('d-none');
        }
    };

    $('.js-postal-code').on('submit', function (e) {
        e.preventDefault();
        const url = $('.js-postal-code').attr('action');
        const input = $('.js-postal-code').find('input');

        let formData = {};
        let inputId = input.attr('id');
        formData[inputId] = input.val();

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: formData,
            success: function (response) {
                if (response.success) {
                    window.location.reload();
                }
            }
        });
    });

    $('[data-reset-postal-code]').on('click', function (e) {
        const url = $(this).data('url');
        let formData = {};
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: formData,
            success: function (response) {
                if (response.success) {
                    window.location.reload();
                }
            }
        });
    });
});
