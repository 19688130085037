/* globals google */
'use strict';
/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map(function (key) {
                return key + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

    return newUrl;
}
/**
 * Uses google maps api to render a map
 */
function maps() {
    var map;
    var infowindow = new google.maps.InfoWindow();

    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(-34.604713, -58.43429);
    var mapOptions = {
        scrollwheel: false,
        zoom: 10,
        center: latlng
    };

    map = new google.maps.Map(
        $('#map-canvas-pickup-clickandcollect')[0],
        mapOptions
    );
    var mapdiv = $('#map-canvas-pickup-clickandcollect').attr('data-locations');

    mapdiv = JSON.parse(mapdiv);
    var bounds = new google.maps.LatLngBounds();

    // Customized google map marker icon with svg format
    var markerImg = {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: '#e60c19',
        fillOpacity: 1,
        scale: 1,
        strokeColor: 'white',
        strokeWeight: 0.8,
        anchor: new google.maps.Point(0, 0),
        labelOrigin: new google.maps.Point(0, -30)
    };

    var mapdivKeys = Object.keys(mapdiv);
    var filteredForVisualRenderInMap = false;

    mapdivKeys.forEach(function (key) {
        var item = mapdiv[key];

        if (item.filteredForVisualRenderInMap) {
            filteredForVisualRenderInMap = true;
        }
        var label = parseInt(key, 10) + 1;
        var storeLocation = new google.maps.LatLng(
            item.latitude,
            item.longitude
        );
        var marker = new google.maps.Marker({
            position: storeLocation,
            map: map,
            title: item.name,
            icon: markerImg,
            label: { text: label.toString(), color: 'white', fontSize: '13px' }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        //  This bounding box can be used to ensure that all specified points are visible within a map's viewport. .
        // Each marker is included in the bound, so all are going to be displayed
        if (!filteredForVisualRenderInMap || item.filteredForVisualRenderInMap) {
            bounds.extend(marker.position);
        }
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapdiv && mapdiv.length !== 0) {
        map.fitBounds(bounds);
    }
}
/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */

function updateStoresResults(data) {
    $('.invalid-feedback-click').text('');
    var hasClickAndCollectOnCart = $('#hasClickAndCollect').length > 0 ? JSON.parse($('#hasClickAndCollect').val()) : false;
    var hasMoreClickAndCollectEnabled = $('#MoreClickAndCollectEnabled').length > 0 ? JSON.parse($('#MoreClickAndCollectEnabled').val()) : false;
    var $resultsDiv = $('.results');
    var $mapDiv = $('#map-canvas-pickup-clickandcollect');
    var hasResults = data.stores.length > 0;
    $resultsDiv
        .empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);
    $mapDiv.attr('data-locations', data.locations);
    if ($mapDiv.data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    $resultsDiv = $('#inStoreInventoryModal').find('.results');

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
        if (hasClickAndCollectOnCart && hasMoreClickAndCollectEnabled) {
            $('#inStoreInventoryModal .results-quantity').addClass('d-none');
        }
        $('.store-map-link').on('click', function () {
            var clickStoreNameGtm = {
                event: 'trackEvent',
                eventCategory: 'cta',
                eventAction: 'click en link de sucursal',
                eventLabel: $(this).prop('innerText')
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(clickStoreNameGtm);
        });
        $('.storelocator-phone').on('click', function () {
            var clickStoreNameGtm = {
                event: 'trackEvent',
                eventCategory: 'cta',
                eventAction: 'click en teléfono de sucursal',
                eventLabel: $(this).prop('innerText')
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(clickStoreNameGtm);
        });
    }
}
function search(element) {
    var dialog = element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $form = element.closest('.store-locator');
    var radius = $('.results').data('radius');
    var currentQuantityValue = parseInt($('.pz-quantity-select').val(), 10);
    var url = $form.attr('action');
    var urlParams = { radius: radius, quantity: currentQuantityValue };
    var shipmentUUID = $('#inStoreInventoryModal').attr('shipmentuuid');
    var payload = {
        postalCode: $form.find('[name="postalCode"]').val(), shipmentUUID: shipmentUUID
    };
    url = appendToUrl(url, urlParams);
    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: payload,
        dataType: 'json',
        success: function (data) {
            spinner.stop();
            updateStoresResults(data);
            $('add-to-cart-color').attr('disabled');
            $('.select-store').prop('disabled', true);
            $('.btn-click-collect').prop('disabled', true);
        }
    });
    return false;
}
module.exports = {
    selectStore: function () {
        $('.store-locator-container').on('click', '.btn-click-collect', function (e) {
            e.preventDefault();
            var dataBuyNow = $(this).data('buy-now');
            var selectedStore = $(':checked', '.results-card .results');
            var data = {
                storeID: selectedStore.val(),
                searchRadius: $('#radius').val(),
                searchPostalCode: $('.results').data('search-key').postalCode,
                storeDetailsHtml: selectedStore
                    .siblings('label')
                    .find('.store-details')
                    .html(),
                storeInfo: JSON.parse(selectedStore.attr('data-store-info')),
                isBuyNow: dataBuyNow,
                event: e
            };
            /**
             * DAB-4756 BEGIN - push datalayer Select store event Google Tag manager
             */
            var storeSelectedGtmEvent = {
                event: 'trackEvent',
                eventCategory: 'cta',
                eventAction: `sucursal: ${
                    JSON.parse(selectedStore.attr('data-store-info')).name
                }`,
                eventLabel: `Id de sucursal: ${selectedStore.val()}`
            };
            if (
                selectedStore.attr('data-store-info') !== null &&
                selectedStore.attr('data-store-info') !== ''
            ) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(storeSelectedGtmEvent);
            }
            /**
             * DAB-4756 END
             */
            $('button.submit-shipping').attr('disabled', false);
            $('body').trigger('store:selected', data);
        });
    },
    updateSelectStoreButton: function () {
        $('body').on('change', '.select-store-input', function () {
            $('.select-store').prop('disabled', false);
            $('.btn-click-collect').prop('disabled', false);
        });
    },
    search: function () {
        $('.store-locator-container form.store-locator').submit(function (e) {
            e.preventDefault();
            search($(this));
        });
        $(
            '.store-locator-container .btn-storelocator-search-click[type="button"]'
        ).click(function (e) {
            if ($(this).siblings('.form-group').find('#store-postal-code').val() === '') {
                $(this).siblings('.invalid-feedback-click').text('Coloca un código postal');
                e.stopPropagation();
                return;
            }
            $(this).siblings('.invalid-feedback-click').text('');

            e.preventDefault();
            search($(this));
        });
        var postalCodeInput = $('#storeCC');
        postalCodeInput.on('keydown', function (e) {
            if (e.code === 'Enter') {
                $('.open-locator-clickandcollect').trigger('click');
            }
        });
    },
    init: function () {
        if ($('.open-locator-clickandcollect')) {
            $('.open-locator-clickandcollect').on('click', function (e) {
                var postalCodeValidation = $(this).siblings('#storeCC');
                if (postalCodeValidation.val() === '') {
                    $(this).closest('.clickandcollect').find('.invalid-feedback-click').text('Coloca un código postal');
                    e.stopPropagation();
                    return;
                }
                $(this).closest('.clickandcollect').find('.invalid-feedback-click').text('');
                e.preventDefault();

                var postalCodeInput = $(e.currentTarget).parent().find('#storeCC');
                var storeLocatorModal = $('#inStoreInventoryModal');
                var modalPostalCodeInput =
                    storeLocatorModal.find('#store-postal-code');
                var modalSearchButton = storeLocatorModal
                    .parent()
                    .find('.btn-storelocator-search-click');

                var shipmentUUID = postalCodeInput.closest('.shipping-form').find('[name="shipmentUUID"]').val();

                if (postalCodeInput.closest('.shipping-form').find('[name="productLineItemUUID"]').length > 0) {
                    var pliUUID = postalCodeInput.closest('.shipping-form').find('[name="productLineItemUUID"]').val();
                    storeLocatorModal.attr('productLineItemUUID', pliUUID);
                    storeLocatorModal.attr('isMulti', true);
                }

                var originalShipmentUUID = postalCodeInput.closest('.shipping-form').find('[name="originalShipmentUUID"]').val();
                modalPostalCodeInput.val(postalCodeInput.val());

                storeLocatorModal.attr('shipmentUUID', shipmentUUID);
                storeLocatorModal.attr('originalShipmentUUID', originalShipmentUUID);
                var inputId = document.getElementById('store');
                inputId.value = '';
                $('#inStoreInventoryPostalCode .close-first-modal').click();

                search(modalSearchButton);
            });
        }
        if ($('#map-canvas-pickup-clickandcollect').data('has-google-api')) {
            maps();
        } else {
            $('.store-locator-no-apiKey').show();
        }

        if (!$('.results').data('has-results')) {
            $('.store-locator-no-results').show();
        }
    },

    detectLocation: function () {
        // clicking on detect location.
        $('.detect-location-click').on('click', function (e) {
            $.spinner().start();
            var currentLocationLink = $(this);
            if (!navigator.geolocation) {
                $.spinner().stop();
                e.stopPropagation();
                return;
            }
            navigator.geolocation.getCurrentPosition(function (position) {
                var $detectLocationButton = $('#inStoreInventoryPostalCode').length > 0 ? $('.store-locator') : currentLocationLink.closest('.store-locator');
                var radius = $('.results').data('radius');
                var url = $detectLocationButton.attr('action');
                var shipmentUUID = $('#inStoreInventoryModal').attr('shipmentuuid');

                var urlParams = {
                    radius: radius,
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                    shipmentUUID: shipmentUUID
                };
                url = appendToUrl(url, urlParams);
                var inputId = document.getElementById('store-postal-code');
                inputId.value = '';
                $('#inStoreInventoryPostalCode .close-first-modal').click();
                $.ajax({
                    url: url,
                    type: $('.store-locator').attr('method'),
                    dataType: 'json',
                    success: function (data) {
                        $.spinner().stop();
                        updateStoresResults(data);
                        $('add-to-cart-color').attr('disabled');
                        $('.select-store').prop('disabled', true);
                        $('.btn-click-collect').prop('disabled', true);
                    }
                });
            });
        });
    },
    mapCallout: maps
};
