var coreProductBase = require('./base');

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processAttributeSizeValue(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';

    var $defaultOption = $productContainer.find("ul[class='variation-attribute-size']");
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeClass('disabled');

        if (!attrValue.selectable) {
            $attrValue.addClass('disabled');
        }

        if (attrValue.selected) {
            $attrValue.addClass('selected');
        }

        if (!attrValue.selected) {
            $attrValue.removeClass('selected');
        }
    });
}
function getEventPersonalizationsModal() {
    var lengthFormName = $('#personalization-form-name').val() ? $('#personalization-form-name').val().length : 0;
    var lengthFormNumber = $('#personalization-form-number').val() ? $('#personalization-form-number').val().length : 0;
    if ((lengthFormName + lengthFormNumber) === 0) {
        $('.summaryContainer-PDP').addClass('d-none');
    }
}
function creatingArrayForNumberOptions() {
    arrayOptionNumberPersonalization = $.map(
        $('#numberPersonalization select.options-select option'),
        function (option) {
            return {
                // We get the id that is stored in the ISML attribute data-value-id
                optionId: option.dataset.valueId,

                // The value attribute contains the URL to request for a option change
                value: option.value,

                // We also get useful prices values, added by customization on data attributes
                price: option.dataset.price,
                priceValue: Number(option.dataset.priceValue)
            };
        }
    );
}
function creatingArrayForNameOptions() {
    arrayOptionNamePersonalization = $.map(
        $('#namePersonalization select.options-select option'),
        function (option) {
            return {
                // We get the id that is stored in the ISML attribute data-value-id
                optionId: option.dataset.valueId,

                // The value attribute contains the URL to request for a option change
                value: option.value,

                // We also get useful prices values, added by customization on data attributes
                price: option.dataset.price,
                priceValue: Number(option.dataset.priceValue)
            };
        }
    );
}
function simulateSelectElementChangeEvent(selector, newValue) {
    $(selector) // Query the div that wraps the <select> element
        .find('select.options-select') // Gets the select element
        .val(newValue); // Updates the option selected with the corresponding value
    // Fires the event 'change' in order to call the corresponding handler.
}
function personalizationNameConstructor() {
    getEventPersonalizationsModal();
    creatingArrayForNameOptions();
    var lengthFormName = $('#personalization-form-name').val() ? $('#personalization-form-name').val().length : 0;
    var nameValue = arrayOptionNamePersonalization[lengthFormName] ? arrayOptionNamePersonalization[lengthFormName].value : 0;
    simulateSelectElementChangeEvent(
        '[data-option-id="namePersonalization"]',
        nameValue
    );
}
function personalizationNumberConstructor() {
    getEventPersonalizationsModal();
    creatingArrayForNumberOptions();
    var lengthFormNumber = $('#personalization-form-number').val() ? $('#personalization-form-number').val().length : 0;
    var numberValue = arrayOptionNumberPersonalization[lengthFormNumber] ? arrayOptionNumberPersonalization[lengthFormNumber].value : 0;
    simulateSelectElementChangeEvent(
        '[data-option-id="numberPersonalization"]',
        numberValue
    );
}

function setPersonalizations(personalizationData) {
    $('#personalization-form-name').val(personalizationData.personalization.nameModal);
    $('#personalization-form-number').val(personalizationData.personalization.numberModal);
    $('.nameSummary').text(personalizationData.personalization.nameModal);
    $('.namePriceSummary').text(personalizationData.personalization.costNameModal);
    $('.numberSummary').text(personalizationData.personalization.numberModal);
    $('.numberPriceSummary').text(personalizationData.personalization.costNumberModal);
    $('.finalPriceSummary').text(personalizationData.personalization.totalCostModal);
    $('.nameSummary-PDP').text(personalizationData.personalizationPDP.nameModal);
    $('.namePriceSummary-PDP').text(personalizationData.personalizationPDP.costNameModal);
    $('.numberSummary-PDP').text(personalizationData.personalizationPDP.numberModal);
    $('.numberPriceSummary-PDP').text(personalizationData.personalizationPDP.costNumberModal);
    $('.finalPriceSummary-PDP').text(personalizationData.personalizationPDP.totalCostModal);
    $('.summaryContainer-PDP').removeClass('d-none');
    $('.addNewPersonalization').text('Editar');
    personalizationNameConstructor();
    personalizationNumberConstructor();
}

module.exports = {
    handleVariationAttributeSizeResponse: function () {
        // This event is fired on product/base.js > function attributeSelect
        var pageUUID = $('#pageUUID').val();
        $('body').on('product:afterAttributeSelect', function (e, { data, container }) {
            const $productContainer = container;
            const response = data;
            $('[data-js-display-shipping-methods]').prop('disabled', false);

            // On > product/base.js > function handleVariantResponse
            if (response.product.variationAttributes) {
                // On > product/base.js > function updateAttrs
                const attrs = response.product.variationAttributes;

                // Then we process size attribute in a custom way
                // Note: the attribute size is still handled on:
                // product/base.js > function processNonSwatchValues but this
                // should not affect the total result
                attrs.forEach(function (attr) {
                    if (attr.attributeId === 'size') {
                        // Custom handler implementation for Product 'size' attribute
                        processAttributeSizeValue(attr, $productContainer);
                    }
                });
            }
            if (localStorage.getItem(pageUUID + '_' + response.product.id + '_personalizationsData')) {
                var personalizationsDataGet = JSON.parse(Buffer.from(localStorage.getItem(pageUUID + '_' + response.product.id + '_personalizationsData'), 'base64').toString(
                    'utf-8'
                ));
                setPersonalizations(personalizationsDataGet);
                $('.btn-get-in-store-inventory').prop('disabled', true);
            }
        });
    },

    // Custom event listener implementation for Product 'size' variation attribute
    sizeAttribute: function () {
        // $(document).on('click', 'li[class*="variation-attribute-size"], div[class*="variation-attribute-size-values"]', function (e) {
        $(document).on('click', 'li[class*="variation-attribute-size"]', function (e) {
            e.preventDefault();

            /* We handle the behaviour of attribute "reset"
      with the <ul> container, so we prevent propagation
      in the case we just clicked an <li> element */
            e.stopPropagation();

            /* We get attribute value in this way instead of e.currentTarget.value,
      because the li items does not have value attribute. For more information check:
      https://stackoverflow.com/questions/27946611/list-item-value-is-always-0-using-jquery */
            const value = $(e.currentTarget).attr('value');

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            coreProductBase.attributeSelect(value, $productContainer);
        });
    }
};
