// dataLayer thin Banner on header
$('.header-banner').on('click', function (e) {
    let element = $(e.target);
    let validateContent;
    if (this.innerText) {
        validateContent = this.innerText;
    } else {
        validateContent = this.getAttribute('src');
    }
    var thinBannerClick = {
        event: 'trackEvent',
        eventCategory: 'header',
        eventAction: 'thinBannerClick',
        eventLabel: element.innerText ? element.innerText : element.attr('src')
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(thinBannerClick);
});
// dataLayer menu and submenu buttons
$('.navbar-nav .nav-item').on('click', function (e) {
    let element = $(e.target);
    var menuClick = {
        event: 'trackEvent',
        eventCategory: 'menu',
        eventAction: 'buttonClick',
        eventLabel: element.attr('id')
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(menuClick);
});
// dataLayer whatsapp Icon click
$('.whatsapp_icon').on('click', function (e) {
    var whatsAppIcon = {
        event: 'trackEvent',
        eventCategory: 'click',
        eventAction: 'button pop up',
        eventLabel: this.className
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(whatsAppIcon);
});
// dataLayer login Icon click
$('.login-userPanel_Btn').on('click', function (e) {
    var loginIcon = {
        event: 'trackEvent',
        eventCategory: 'click',
        eventAction: 'header icon',
        eventLabel: this.className.split(' ')[0]
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(loginIcon);
});
// dataLayer favorites Icon click
$('.wishlist-header-icon').on('click', function (e) {
    var favoritesIcon = {
        event: 'trackEvent',
        eventCategory: 'click',
        eventAction: 'header icon',
        eventLabel: this.className.split(' ')[0]
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(favoritesIcon);
});
// dataLayer minicart Icon click
$('.minicart-link').on('click', function (e) {
    var minicartLinkBtn = {
        event: 'trackEvent',
        eventCategory: 'click',
        eventAction: 'header icon',
        eventLabel: this.className
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(minicartLinkBtn);
});
// outbound links tracker
$('a').click(function (e) {
    var link = $(this).attr('href');
    if (
        link &&
        link.indexOf('://') !== -1 &&
        link.indexOf(location.hostname) === -1
    ) {
        var outboundLink = {
            event: 'trackEvent',
            eventCategory: 'Outbound Link',
            eventAction: 'click',
            eventLabel: link
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(outboundLink);
    }
});
// dataLayer newsletter subscription button click
$('#NewsLetter-btn').on('click', function (e) {
    var newsletter = {
        event: 'trackEvent',
        eventCategory: 'formElement',
        eventAction: 'home',
        eventLabel: this.className.split(' ')[2]
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(newsletter);
});
// dataLayer newsletter popup
$('.popup-newsletter').on('click', function (e) {
    var newsletterPopup = {
        event: 'trackEvent',
        eventCategory: 'formElement',
        eventAction: 'home',
        eventLabel: this.className.split(' ')[0]
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(newsletterPopup);
});
// footer elements interaction
$('.footer-container a').on('click', function (e) {
    var footerInteraction = {
        event: 'trackEvent',
        eventCategory: 'cta',
        eventAction: 'cta-click-footer',
        eventLabel: this.innerText
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(footerInteraction);
});
// social sharing click links on PDP
$('.social-icons a').on('click', function (e) {
    let selectedMedia = $(this).attr('title').split(' ');
    var socialSharing = {
        event: 'trackEvent',
        eventCategory: 'share',
        eventAction: 'click',
        eventLabel: selectedMedia[selectedMedia.length - 1]
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(socialSharing);
});
setTimeout(function () {
    $('.styles_button_main__2rvIi').on('click', function () {
        var exchangeIconGtm = {
            event: 'trackEvent',
            eventCategory: 'click',
            eventAction: 'button pop up cambios',
            eventLabel: this.className
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(exchangeIconGtm);
    });
}, 1000);
let waitForSizeTable = () => {
    const container = $('#szb-chart-button');
    if (container.length < 1) {
        return;
    }
    container.on('click', function (e) {
        var sizeChartGtm = {
            event: 'trackEvent',
            eventCategory: 'click',
            eventAction: 'Tabla de talles',
            eventLabel: $(this).attr('id')
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(sizeChartGtm);
    });
    setTimeout(() => clearInterval(interval));
};
// Clear the interval after 10 seconds, in order to avoid cicling in call stack
setTimeout(() => clearInterval(interval), 10000);
var interval = window.setInterval(waitForSizeTable, 100);
let waitForVirtualTester = () => {
    const container = $('#szb-vfr-button');
    if (container.length < 1) {
        return;
    }
    container.on('click', function (e) {
        var clothTesterGtm = {
            event: 'trackEvent',
            eventCategory: 'click',
            eventAction: 'Probador virtual',
            eventLabel: $(this).attr('id')
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(clothTesterGtm);
    });
    setTimeout(() => clearInterval(interval2));
};
var interval2 = window.setInterval(waitForVirtualTester, 100);
// Clear the interval after 10 seconds, in order to avoid cicling in call stack
setTimeout(() => clearInterval(interval2), 10000);
// dataLayer videocall button
let waitForVideoCallButton = () => {
    const container = $('#VivetWidget');
    if (container.length < 1) {
        return;
    }
    container.on('click', function () {
        var videoCallWidget = {
            event: 'trackEvent',
            eventCategory: 'click',
            eventAction: 'assistance videocall button',
            eventLabel: $(this).attr('id')
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(videoCallWidget);
    });
    // Modify position of the videocall button.
    container.css('inset', 'unset');
    container.css('right', '0');
    container.css('bottom', '3.5rem');
    container.css('z-index', '1040');
    // Modify position of the videocall label.
    container.on('mouseenter', function () {
        $('#idtext').css('top', 'unset');
        $('#idtext').css('left', 'unset');
        $('#idtext').css('bottom', '8rem');
        $('#idtext').css('right', '0rem');
        $('#idtext').css('margin-right', '0rem');
    }
    );
    setTimeout(() => clearInterval(interval3));
};
var interval3 = window.setInterval(waitForVideoCallButton, 100);
// Clear the interval after 10 seconds, in order to avoid cicling in call stack
setTimeout(() => clearInterval(interval3), 10000);